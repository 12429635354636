$vox-gtEestiPro-font: 'GT-Eesti-Pro', 'Arial', 'Helvetica', sans-serif;
$black: #000000;
$lighter-grey: #f5f7f9;
$light-grey: #e5e9f0;
$dark-grey: #868990;
$medium-grey: #b5bac2;
$lighter-grey: #f5f7f9;
$white: #ffffff;
$royal-blue: #0066ff;
$bright-crimson: #ff3465;
$gold: #fde062;
$purple: #6515dd;
$aquamarine: #25edb5;
$peach: #ff8d7a;



//  to check and maybe remove
$font-color: #606060;

$font-color-light: lighten($font-color, 30%);
$bright-crimson-trasp: rgba($bright-crimson, 60%);
$inactive-bright-crimson: desaturate($bright-crimson-trasp, 80%);
