/* You can add global styles to this file, and also import other style files */
@import "./normalize";
@import "./variables";
@import "./fonts";
@import "../node_modules/voxloud-ui-toolkit/dist/css/style.min.css";

/* Variables */
@mixin font-regular {
    font-family: $vox-gtEestiPro-font;
    font-weight: normal
}

@mixin font-bold {
    font-family: $vox-gtEestiPro-font;
    font-weight: bold
}

body {
  font-size: 100%;
  line-height: 18px;
  width: 100%;
  @include font-regular;
  overflow: auto;

  p {
    color: $font-color;
    font-size: 0.85rem;
  }

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      color: $black
    }
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none
  }

}

.title {
  padding: 20px 10px;
  text-align: center;
  font-size: 1.1rem;
  color: $black;
  @include font-bold;

  i {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 2rem;
    color: $black;
    cursor: pointer
  }

  .pull-left {
    position: absolute;
    top: 10px;
    left: 0;
  }
}

.pull-right {
    float: right
}

.group-title {
  background: $light-grey;
  margin: 0 9px;
  padding: 15px 10px;
  @include font-bold;

  &.number {
    background: $black;
    color: $white;
    text-align: center
  }

  &.credit {
    background: $black;
    color: $white;
    text-align: left
  }
}

.list {
  height: inherit;
  margin: 0;
  font-size: 0.95rem;

  ul {
    padding: 0;
    margin: 0 9px;

    li {
      list-style: none;
      padding: 15px 0;
      padding-left: 5px;
      border-bottom: $light-grey solid 1px;
      cursor: pointer;
      position: relative;

      /* strong{    vertical-align: super;} */
      i {
        width: 30px;
        display: inline-block;
        margin-right: 8px;
        background-size: contain;
        vertical-align: middle
      }

      span {
        float: right;
        padding-top: 4px;
        padding-right: 12px;
        @include font-regular;
      }

      .pull-right {
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
      }

      &.number {
        color: $inactive-bright-crimson;

        i {
          font-size: 1rem;
          padding-top: 18px;
          text-align: right
        }

        span {
          float: none
        }

        span:first-child {
          font-weight: bold;
          font-size: 1.05rem
        }

        span:last-child {
          opacity: 0.7
        }

        &.active {
          color: $black !important;
          font-weight: bold;
        }
      }

      .contact-name {
        margin-right: 5px;
      }
    }

    &.features {
      li {
        i {
          font-size: 1.5rem;
          padding-top: 15px;
          color: $black
        }
      }

      li:hover a {
        color: $black !important
      }
    }
  }
}


.main .list ul li:hover,
.voverc .list ul li:hover,
.callhistory .list ul li:hover {
  background: $lighter-grey;
  color: $black !important;
}

.main .list ul li:hover strong,
.main .list ul li:hover span {
  color: $black !important;
}
